

































































































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableActionButton from '@/@core/components/table/KTableActionButton.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';
import { useClassificationModel } from './model';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
import ClassificationForm from '../../ui/ClassificationForm.vue';
import { useTableOptions } from '@/@core/helpers/table-options';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTableWrapper,
    KTextInput,
    KTableSearch,
    KTableActionButton,
    KTableActions,
    KDialog,
    ClassificationForm
  },
  name: 'ClassificationDefaultPage',
  setup() {
    const model = useClassificationModel();
    const tableOptions = ref(useTableOptions());

    const getManyClassification = async () => {
      await model.onGetManyClassification(tableOptions, model.search);
    };

    watch(tableOptions, async () => await getManyClassification());
    watch(model.search, async () => {
      tableOptions.value.page = 1;
      await getManyClassification();
    });

    return {
      isMobile,
      tableOptions,
      ...model
    };
  }
});
